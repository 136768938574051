import React, {useRef,useEffect, useLayoutEffect, useState} from "react"
import {motion, useAnimation, useSpring} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useIsMobile} from "../utils";
import Hero from "../components/hero";
import Contact from "../components/contact";
import { SEO } from '../components/seo';


const GuidesMenuItem = ({ english, name, content, imageUrl, onClick }) => <div
  style={{ 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}
>
  <img
    src={imageUrl}
    style={{ width: '100%', cursor: 'pointer' }}
    onClick={onClick}
  />
  <div style={{ height: 24 }}></div>
  <div className="title3">{english}</div>
  <div style={{ height: 4 }}></div>
  <div className="title2">{name}</div>
  <div style={{ height: 16 }}></div>
  <div
    className="body1"
    style={{ maxWidth: 300, textAlign: 'justify' }}
  >{content}</div>
</div>

const GuideStep = ({ direction = 'right', header = '', content, imageUrl }) => {
    const { isMobile } = useIsMobile();
    const controls = useAnimation();
    const [ref, inView] = useInView();

    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
        },
    };

    useEffect(() => {
        if (inView) {
          controls.start("visible");
        }
    }, [controls, inView]);

  const Picture = () => <div
    style={{ 
      ...(isMobile ? 
        { width: '100%' } : 
        { width: '48%' }),
      background: `url("${imageUrl}")`,
      backgroundSize: 'cover',
        backgroundPosition: 'center',
        aspectRatio: '1',
    }}
  />

    const Description = () =>
    <div style={{ 
      display: 'flex',
      background: '#3C3B29',
      color: 'white',
      verticalAlign: 'middle',
      padding: '24px',
      aspectRatio: '1',
      alignItems: 'center',
      ...(isMobile ? 
        { width: '100%' } : 
        { width: '48%' })
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', margin: '0px auto', maxWidth: 424 }}>
        { header && <div className="title2" style={{ color: 'white' }}> 
              {header}
          </div>}
          <div className="body1" style={{ color: 'white'}}>
              {content}
          </div>
      </div>
    </div>

    if (isMobile) {
        return (
            <motion.div
                style={{ display: 'flex', padding: '0px 24px', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '24px'}}>
                <Picture />
                <Description />
            </motion.div>
        );
    }
    return (
        <motion.div
            style={{ width: '100%', justifyContent: 'center', gap: '24px', display: 'flex', maxWidth: '1200px', margin: '0px auto'  }}>
            {
                direction === 'right' ? [
                    <Picture key="story-pic" />,
                    <Description key="story-content"/>
                ] : [
                    <Description key="story-content"/>,
                    <Picture key="story-pic"/>,
                ]
            }
        </motion.div>
    );
}

const PourStory = () => <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  }}
>
  <GuideStep
    direction="left"
    header="手沖沖煮製作"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/pour-step1.png"
    content={
      <p
      >
        1.
        <br />
        確認所有手沖器具都在手邊。盛裝熱水至手沖壺內，熱水的溫度——冬天建議 92-90 度，夏天建議 91-89 度，水粉比例為：18 公克咖啡豆：250 毫升水。
        <br />
        <br />
        2.
        <br />
        我們建議要沖煮咖啡的時候再做研磨咖啡豆，咖啡豆在香氣表現會更好，研磨度為手沖研磨。剛烘焙好的咖啡豆需要養豆的時間，我們這邊會建議手沖的養豆時間是  7 天，這樣在沖煮時可以獲得更多的風味以及甜感。
      </p>
    }
  />
  <GuideStep
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/pour-step2.png"
    content={
      <p>
        3.
        <br />
        準備濾紙、濾杯以及下壺。請先用熱水溫杯，將濾紙放入濾杯中，再倒入研磨好的咖啡粉至濾杯中，輕輕拍晃使咖啡粉平整。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/pour-step3.png"
    content={
      <p>
        4.
        <br />
        檢查水溫是否是你設定想要的溫度，第一次注水—悶蒸是關鍵，從濾杯中心開始倒水，倒約 40cc 的水，悶蒸 30 至 40 秒，此時會看到咖啡粉逐漸膨脹，觀察粉層膨脹的狀況，並等待至上壺不滴咖啡，即可進行第二次注水。
      </p>
    }
  />
  <GuideStep
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/pour-step4.png"
    content={
      <p>
        5.
        <br />
        第二次注水——萃取咖啡，由內而外，統一方向，從濾杯中心慢慢旋轉向外持續平均注水,注水總重量達到 50 公克後，停止注水，待粉層下降再進行第三次注水。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/pour-step5.png"
    content={
      <p>
        6.
        <br />
        完成沖煮：第三次注水——重複第五個步驟，注水重量達 50 公克後停止注水，直到達到 250 克，過程約 2 分至 2 分 10 秒。
      </p>
    }
  />
</div>;

const FrenchStory = () => <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  }}
>
  <GuideStep
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/french-step1.png"
    header="法式濾壓壺"
    content={
      <p>
        1.
        <br />
        確保所需要的咖啡器具都在手邊，準備熱水，水溫 93 至 91 度，沖泡時間 4 分鐘。
        <br />
        水粉比為：咖啡豆 10公克，200毫升水。
        <br />
        沖泡時間 4 分鐘。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/french-step2.png"
    content={
      <p>
        2.
        <br />
        使用熱水預熱法式濾壓壺，溫壺完成再把水倒出。
      </p>
    }
  />
  <GuideStep
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/french-step3.png"
    content={
      <p>
        3.
        <br />
        將咖啡豆顆粒度研磨至中偏粗。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/french-step4.png"
    content={
      <p>
        4.
        <br />
        將預熱好的熱水倒入壺中，沖泡咖啡粉，再輕輕的來回攪拌一下，確保咖啡粉都有均勻浸泡到熱水，計時四分鐘。
      </p>
    }
  />
  <GuideStep
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/french-step5.png"
    content={
      <p>
        5.
        <br />
        時間到把濾網壓下去，即可倒出飲用。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/french-step6.png"
    content={
      <p>
        6.
        <br />
        風味品嚐——由於法式濾壓壺中附有金屬濾網，此外加上水跟咖啡粉直接浸泡，這讓它有一個特點，就是咖啡油脂會更豐富，因此在風味上具有更厚重飽滿的口感，也會喝到一些咖啡渣及細粉。不過不喜歡喝到咖啡渣的朋友可以另選其他適合自己的沖煮器具。
      </p>
    }
  />
</div>;

const IceStory = () => <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  }}
>
  <GuideStep
    header="冰滴咖啡"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/ice-step1.png"
    content={
      <p>
        1.
        <br />
        確認所有手沖器具都在手邊。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/ice-step2.png"
    content={
      <p>
        2.
        <br />
        準備 40 - 45 公克的咖啡豆 ，研磨至手沖的顆粒度，再將咖啡粉倒入冰滴壺的濾杯中，輕拍使它平整。
      </p>
    }
  />
  <GuideStep
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/ice-step3.png"
    content={
      <p>
        3.
        <br />
        倒入常溫的水 50cc，浸濕咖啡粉，慢慢的澆淋在咖啡粉上，水慢慢滲透咖啡粉，不建議快速的一次倒入太多的水，容易導致咖啡粉未浸泡到就直接留下去下壺。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/ice-step4.png"
    content={
      <p>
        4.
        <br />
        注水完成後，輕輕攪拌咖啡粉，確保下層的咖啡粉都有均勻浸泡到水。攪拌過程中會有咖啡粉跑到外面，可以重新填補回去，輕敲使咖啡粉緊實不鬆散即可，再將圓形濾片輕放在咖啡粉上，濾片平整不傾斜。
      </p>
    }
  />
  <GuideStep
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/ice-step5.jpg"
    content={
      <p>
        5.
        <br />
        將上座加滿 600cc 的冰塊水， 要記得確認下面的開關是鎖緊的，避免水直接滲出，導致粉成破碎。
      </p>
    }
  />
  <GuideStep
    direction="left"
    imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/ice-step6.jpg"
    content={
      <p>
        6.
        <br />
        調整 3-4 秒為一滴的速度，萃取的時間為 3-4 小時， 咖啡粉進行滴漏的時候，有時候會遇到水溢出的狀態，可以先停止滴漏，攪拌一下咖啡粉，等溢出的水滴漏完,再次進行滴漏即可解決。會有這樣的狀況是因為咖啡豆比較新鮮正在排氣，金屬濾網比較細，所以水會有溢出的狀況。
      </p>
    }
  />
</div>



const Guides = ({
                   data,
                   pageContext: {nextPagePath, previousPagePath},
               }) => {
    const {isMobile} = useIsMobile();
   const [currentGuide, setCurrentGuide] = useState<any>(null);
   const ref = useRef() as React.MutableRefObject<HTMLInputElement>;


const guidesMenuData = [
  {
    imageUrl:"https://cdn.shopify.com/s/files/1/0645/0436/5310/files/guides-pour.jpg",
    english: 'Pour Over',
    name: "手沖沖煮",
    content: "準備：濾杯、濾紙、下壺、磨豆機、手沖壺、計時器、磅秤、咖啡豆",
    onClick: () => {
      setCurrentGuide(PourStory);
      document.querySelector('html').scrollTo(0,0);
    }
  },
  {
    imageUrl:"https://cdn.shopify.com/s/files/1/0645/0436/5310/files/guides-french.jpg",
    english: 'French Press',
    name: "法式濾壓壺",
    content: "準備：法式濾壓壺、磨豆機、計時器、熱水壺、咖啡豆",
    onClick: () => {
      setCurrentGuide(FrenchStory);
      document.querySelector('html').scrollTo(0,0);
    }
  },
  {
    imageUrl:"https://cdn.shopify.com/s/files/1/0645/0436/5310/files/guides-ice.jpg",
    english: 'Cold Drip',
    name: "冰滴",
    content: "準備：driver 冰滴壺、磨豆機、磅秤、冰塊、常溫水、咖啡豆",
    onClick: () => {
      setCurrentGuide(IceStory);
      document.querySelector('html').scrollTo(0,0);
    }
  },
]

  const GuidesMenu = () => {
    const { isMobile } = useIsMobile();
    return (
      <div
        style={{
          padding: '0 64px 32px 64px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 48,
          width: '100%',
        }}
      >
        {
          guidesMenuData.map((data) => <GuidesMenuItem {...data} />)
        }
      </div>
    );
  }

   const GuidesIndex = () => <div>
      <Hero 
        images={[
          'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/guides-hero-1.jpg',
          'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/guides-hero-2.jpg',
        ]}
      />
    <div
        style={{
            padding: '4rem 4rem',
            maxWidth: 732,
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            margin: '0px auto',
        }}
        className="title3"
    >
        GUIDES
        <br/>
        <div className="title1">
          沖煮指南
        </div>
        <div className="body1" style={{textAlign: 'justify', padding: isMobile ? '24px 16px' : '64px 0'}}>
          我們的咖啡豆適合任何一種沖煮方式，法式濾壓壺、手沖咖啡、濃式咖啡、美式咖啡機、或者是冰滴都非常適合。每一種咖啡豆它都有適合的烘焙程度，來呈現咖啡豆它本身原有的風味，除了選擇適合的烘焙程度外，沖煮也是很重要的一環。在這裡，我們提供法式濾壓壺、手沖以及冰滴的沖煮參數，這些器具是在日常生活中，較容易取得的沖煮器具。
          <br />
          <br />
          咖啡豆在烘焙後，會需要時間進行排氣，因為在烘焙的過程中，會產生熱降解反應，在這個反應中，咖啡豆裡的物質會進行分化與合成，產生出香氣以及風味化合物，二氧化碳也是其中之一。這些氣體會存在在咖啡豆的細胞中，隨著時間慢慢的排出，我們在沖煮咖啡的時候，排氣會影響到沖煮萃取的結果，當水因為氣體的阻隔，破壞咖啡粉與水的接觸，導致萃取不均勻，影響了整杯咖啡的香氣和風味。
          <br />
          <br />
          一杯好的咖啡應該出現在生活的每一個角落，運用簡單的器具，享受一杯好咖啡應該是一件簡單的事。
          <br />
          <br />
          使用浸泡式、手沖咖啡豆建議養豆的時間是 7 天，義式濃縮咖啡豆建議養豆的時間是 14 天。
        </div>
    </div>
    <br/>
    <div ref={ref} />
    <GuidesMenu />
    <div style={{ height: 64}} />
    <Contact />
   </div>

    return (
        <div>
            {
              currentGuide ? <div
                style={{
                  margin: '120px 0px',
                }}
              >
                {currentGuide}
                <div
                  style={{ 
                    maxWidth: 1200, 
                    padding: '24px 8px', 
                    margin: '0px auto', 
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'right' 
                  }}
                >
                  <div
                    className="my-btn"
                    style={{
                      width: 'fit-content',
                      padding: "0.5rem",
                      cursor: "pointer",
                      paddingTop: "0.3rem",
                      paddingBottom: "0.3rem",
                      paddingRight: "2rem",
                      paddingLeft: "2rem",
                      border: "1px solid #000",
                      borderRadius: '6px',
                      flexWrap: 'nowrap',
                    }}
                    onClick={() => {
                      setCurrentGuide(null);
                      setTimeout(() => {
                        ref.current.scrollIntoView({ behavior: 'smooth' })
                      }, 100);
                    }}
                  >
                    返回
                  </div>
                </div>
              </div> :
              <GuidesIndex />
            }
        </div>
    )
}

export default Guides;
export const Head = () => (
  <SEO
    title="沖煮指南"
    description="我們的咖啡豆適合任何一種沖煮方式，法式濾壓壺、手沖咖啡、濃式咖啡、美式咖啡機、或者是冰滴都非常適合。每一種咖啡豆它都有適合的烘焙程度，來呈現咖啡豆它本身原有的風味，除了選擇適合的烘焙程度外，沖煮也是很重要的一環。在這裡，我們提供法式濾壓壺、手沖以及冰滴的沖煮參數，這些器具是在日常生活中，較容易取得的沖煮器具。"
    image="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/guides-hero-2.jpg"
  />
)
